// Code generated by Slice Machine. DO NOT EDIT.

import ColumnText from "./ColumnText";
import DeepLink from "./DeepLink";
import Faqs from "./Faqs";
import FeaturedContentCarousel from "./FeaturedContentCarousel";
import FeaturedContentSelector from "./FeaturedContentSelector";
import FeaturedFunds from "./FeaturedFunds";
import FeaturedHero from "./FeaturedHero";
import FeaturedInLogos from "./FeaturedInLogos";
import FeaturedInsights from "./FeaturedInsights";
import FeaturedSlices from "./FeaturedSlices";
import FeaturedVideo from "./FeaturedVideo";
import Header from "./Header";
import InPageNavigation from "./InPageNavigation";
import SliceFooterActions from "./SliceFooterActions";
import Spacer from "./Spacer";
import Testimonials from "./Testimonials";
import TextImage from "./TextImage";
import TextOnImage from "./TextOnImage";
import TextRow from "./TextRow";
import TypeformEmbed from "./TypeformEmbed";

export {
  ColumnText,
  DeepLink,
  Faqs,
  FeaturedContentCarousel,
  FeaturedContentSelector,
  FeaturedFunds,
  FeaturedHero,
  FeaturedInLogos,
  FeaturedInsights,
  FeaturedSlices,
  FeaturedVideo,
  Header,
  InPageNavigation,
  SliceFooterActions,
  Spacer,
  Testimonials,
  TextImage,
  TextOnImage,
  TextRow,
  TypeformEmbed,
};

export const components = {
  three_column_text: ColumnText,
  deep_link: DeepLink,
  faqs: Faqs,
  featured_content_carousel: FeaturedContentCarousel,
  featured_content_selector: FeaturedContentSelector,
  featured_funds: FeaturedFunds,
  featured_hero: FeaturedHero,
  featured_in_logos: FeaturedInLogos,
  featured_insights: FeaturedInsights,
  featured_slices: FeaturedSlices,
  featured_video: FeaturedVideo,
  header: Header,
  in_page_navigation: InPageNavigation,
  slice_footer_actions: SliceFooterActions,
  spacer: Spacer,
  testimonials: Testimonials,
  text_image: TextImage,
  text_on_image: TextOnImage,
  text_row: TextRow,
  typeform_embed: TypeformEmbed,
};
