// App Paths:
export const tillitAppHome =
  process.env.GATSBY_APP_ROOT_PATH || "https://my.tillitinvest.com";

export const hexagonPagePath = tillitAppHome;
export const dashboardPath = `${tillitAppHome}/register?ReturnPath=/dashboard`;
export const fundListPath = `${tillitAppHome}/funds`;

// Old paths
// export const signUpPath = `${tillitAppHome}/register?ReturnPath=/open-account`;
// export const signInPath = `${tillitAppHome}/login?ReturnPath=/dashboard`;
// export const startTransferPath = `${tillitAppHome}/register?ReturnPath=/open-account/transfer`;
export const signUpPath = `${tillitAppHome}/open-account`;
export const signInPath = `${tillitAppHome}/sign-in?return-path=/dashboard`;
export const startTransferPath = `${tillitAppHome}/open-account/transfer`;
export const logoutPath = `${tillitAppHome}/logout?ReturnPath=/signed-out`;
