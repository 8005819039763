import React from "react";
import { useTheme } from "styled-components";
import {
  FeaturedHeroContainer,
  FeaturedHeroInner,
  ContentContainer,
  MediaContainer,
} from "./FeaturedHero.styles";
import { Padding } from "../../design-system/Container/Container";
import { H1Xl } from "../../design-system/Typography/headings/Headings";

export interface FeaturedHeroProps {
  heading: string;
  children?: JSX.Element | React.ReactNode | string;
  image?: JSX.Element | React.ReactNode | string;
  videoUrl?: string;
  videoPoster?: string;
}

export function FeaturedHero({
  heading,
  children,
  image,
  videoUrl,
  videoPoster,
  ...props
}: FeaturedHeroProps) {
  const media = videoUrl ? (
    <video muted playsInline autoPlay loop poster={videoPoster}>
      <source src={videoUrl} type="video/mp4" />
    </video>
  ) : (
    image
  );
  const theme = useTheme();
  const isDark = theme.theme.page === "dark";

  return (
    <FeaturedHeroContainer
      fullWidth
      padding={Padding.both}
      {...props}
      className={isDark ? "dark-background" : ""}
    >
      <FeaturedHeroInner>
        <ContentContainer>
          <H1Xl>{heading}</H1Xl>
          {children}
        </ContentContainer>

        <MediaContainer>{media}</MediaContainer>
      </FeaturedHeroInner>
    </FeaturedHeroContainer>
  );
}
