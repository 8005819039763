import React from "react";
import { graphql } from "gatsby";
import { SliceZone } from "@prismicio/react";
import { SliceContainer } from "../components/design-system/Slices/SliceContainer/SliceContainer";
import { components } from "../components/slices";
import { Layout } from "../components/layout/Layout";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { Seo } from "../components/Seo";
import { darkTheme } from "../theme/theme";

const allowPreview = process.env.GATSBY_ALLOW_PREVIEW == "true";

interface PageProps {
  data: any;
}

function HomePageTemplate({ data }: PageProps) {
  if (!data) return null;
  const doc = data.prismicHomepage.data;

  return (
    <Layout
      header={doc.hide_header !== true}
      altTheme={doc.page_mode ? darkTheme : undefined}
    >
      <SliceContainer>
        <SliceZone slices={doc.slices} components={components} />
      </SliceContainer>
    </Layout>
  );
}

export const HomePageTemplateWithPreview = withPrismicPreview(HomePageTemplate);

export default allowPreview ? HomePageTemplateWithPreview : HomePageTemplate;

export const Head = ({ data }: PageProps) => {
  const { url } = data.prismicHomepage;
  const { page_title, page_description } = data.prismicHomepage.data;
  const hasImage = data.prismicHomepage.data.og_image.localFile !== null;
  const gatsbyImageData =
    hasImage &&
    data.prismicHomepage.data.og_image.localFile?.childImageSharp
      .gatsbyImageData;

  return (
    <>
      <Seo
        title={page_title}
        description={page_description}
        image={
          hasImage
            ? {
                src: gatsbyImageData.images.fallback.src,
                height: gatsbyImageData.height,
                width: gatsbyImageData.width,
              }
            : {
                src: "/static/og-images/home.png",
                height: 630,
                width: 1200,
              }
        }
        url={url}
      />
    </>
  );
};

export const query = graphql`
  query {
    prismicHomepage {
      _previewable
      id
      url
      type
      data {
        page_title
        page_description
        og_image {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 1200)
            }
          }
        }
        hide_header
        page_mode
        slices {
          ...HeaderDefault
          ...HeaderCustomNav
          ...FeaturedInLogosDefault
          ...TextImageTextImage
          ...TextImageImageText
          ...TextRowDefault
          ...TextRowFullWidth
          ...SpacerDefault
          ...FeaturedHeroDefault
          ...FeaturedHeroTube
          ...FeaturedHeroImage
          ...FeaturedHeroFeatured
          ...FeaturedContentSelectorDefault
          ...ThreeColumnTextDefault
          ...TextOnImageDefault
          ...TextOnImageFullWidth
          ...TypeformEmbedDefault
          ...TypeformEmbedPopup
          ...TypeformEmbedSlide
          ...TestimonialsDefault
          ...TestimonialsSingle
          ...TestimonialsDual
          ...FaqsDefault
          ...FaqsContained
          ...ThreeColumnTextDefault
          ...ThreeColumnTextBoxed
          ...FeaturedInsightsDefault
          ...InPageNavigationDefault
          ...DeepLinkDefault
          ...FeaturedVideoDefault
          ...TextRowWithBorder
          ...FeaturedFundsDefault
          ...FeaturedContentCarouselDefault
        }
      }
    }
  }
`;
