import React, { useState, useEffect, useRef } from "react";
import { ThemeProvider } from "styled-components";
import { theme } from "../../theme/theme";
import GlobalStyle from "../../../global.style";
import { Footer } from "./Footer/Footer";
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from "@mui/material/styles";
import { Header, HeaderShim } from "./Header/Header";
import { DefaultTheme } from "styled-components";
import Banner from "../Banner/Banner";

const muiTheme = createTheme({});

export interface LayoutProps {
  altTheme?: DefaultTheme;
  header?: boolean;
  children: JSX.Element;
}

export function Layout({ altTheme, header = true, children }: LayoutProps) {
  const mainTheme = altTheme ? altTheme : theme;
  const currentDate = new Date().getTime();
  const targetDate = new Date("2024/04/06").getTime();
  const isExpired = currentDate > targetDate;

  return (
    <MuiThemeProvider theme={muiTheme}>
      <ThemeProvider theme={mainTheme}>
        <GlobalStyle />
        {header && (
          <>
            {!isExpired && <Banner />}
            <Header />
            <HeaderShim />
          </>
        )}
        {children}
        <Footer />
      </ThemeProvider>
    </MuiThemeProvider>
  );
}
