import React from "react";
import { useTheme } from "styled-components";
import { Keyboard, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Container, Padding } from "../../design-system/Container/Container";

import {
  AsSeenInContainer,
  ContentWrapper,
  LogoLink,
  LogoImg,
} from "./FeaturedInLogos.styles";

import "swiper/css";
import "swiper/css/pagination";
import SliceFooterActions from "../SliceFooterActions";
import { graphql } from "gatsby";
import { RiskWarningAndDisclosures } from "../../design-system/Slices/Helpers/RiskWarningAndDisclosures";
import CustomRichText from "../../CustomRichText";

/**
 * @typedef {import("@prismicio/client").Content.FeaturedInLogosSlice} FeaturedInLogosSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<FeaturedInLogosSlice>} FeaturedInLogosProps
 * @param { FeaturedInLogosProps }
 */

export interface FeaturedInLogosProps {
  slice: any;
  context?: any;
}

const FeaturedInLogos = ({
  slice,
  context,
  ...props
}: FeaturedInLogosProps) => {
  const hasFooterOptions = slice.primary.cta_options.document;
  const isFullWidth = slice.primary.full_width || false;

  const logosArr = [
    {
      src: "/static/AsSeenInLogos/Citywire.png",
      srcSet:
        "/static/AsSeenInLogos/Citywire.png, /static/AsSeenInLogos/2x/Citywire.png 2x",
      title: "Citywire",
      href: "https://citywire.co.uk/wealth-manager/news/podcast-from-baillie-gifford-manager-to-platform-disruptor/a1388220",
    },
    {
      src: "/static/AsSeenInLogos/Portfolio-Adviser.png",
      srcSet:
        "/static/AsSeenInLogos/Portfolio-Adviser.png, /static/AsSeenInLogos/2x/Portfolio-Adviser.png 2x",
      title: "Portfolio Adviser",
      href: "https://portfolio-adviser.com/tillit-ceo-hjertman-we-want-to-cater-for-investors-curiosity/",
    },
    {
      src: "/static/AsSeenInLogos/corporate-adviser.png",
      srcSet:
        "/static/AsSeenInLogos/corporate-adviser.png, /static/AsSeenInLogos/2x/corporate-adviser.png 2x",
      title: "Corporate Adviser",
      href: "https://corporate-adviser.com/platform-provider-targets-group-sipp-market-in-bid-to-shake-up-workplace-pensions/",
    },
    {
      src: "/static/AsSeenInLogos/Daily-Mail.png",
      srcSet:
        "/static/AsSeenInLogos/Daily-Mail.png, /static/AsSeenInLogos/2x/Daily-Mail.png 2x",
      title: "The Daily Mail",
      href: "https://www.thisismoney.co.uk/money/markets/article-8807487/Ex-fund-manager-eyes-1m-launch-investment-platform.html",
    },
    {
      src: "/static/AsSeenInLogos/OCTO.png",
      srcSet:
        "/static/AsSeenInLogos/OCTO.png, /static/AsSeenInLogos/2x/OCTO.png 2x",
      title: "Octomember",
      href: "https://soundcloud.com/user-511561721/felicia-hjertman-of-vinco",
    },
    {
      src: "/static/AsSeenInLogos/investors-chronicle.png",
      srcSet:
        "/static/AsSeenInLogos/investors-chronicle.png, /static/AsSeenInLogos/2x/investors-chronicle.png 2x",
      title: "Investors Chronicle",
      href: "https://play.acast.com/s/investorschronicle/theinvestmenthour-theplatformparadox",
    },
    {
      src: "/static/AsSeenInLogos/Investment-week.png",
      srcSet:
        "/static/AsSeenInLogos/Investment-week.png, /static/AsSeenInLogos/2x/Investment-week.png 2x",
      title: "Investment Week",
      href: "https://www.investmentweek.co.uk/news/4032339/baillie-gifford-manager-launches-innovative-investment-platform-tillit",
    },
    {
      src: "/static/AsSeenInLogos/The-Sunday-Times.png",
      srcSet:
        "/static/AsSeenInLogos/The-Sunday-Times.png, /static/AsSeenInLogos/2x/The-Sunday-Times.png 2x",
      title: "The Sunday Times",
      href: "https://www.thetimes.co.uk/article/can-you-trust-your-investment-firm-to-pick-a-winner-gtkdfm8bx",
    },
    {
      src: "/static/AsSeenInLogos/MoneyWeek.png",
      srcSet:
        "/static/AsSeenInLogos/MoneyWeek.png, /static/AsSeenInLogos/2x/MoneyWeek.png 2x",
      title: "Money Week",
      href: "https://moneyweek.com/investments/stockmarkets/602508/a-look-back-on-a-peculiar-year-ahead-to-the-recovery-and-what-to",
    },
    {
      src: "/static/AsSeenInLogos/WM.png",
      srcSet:
        "/static/AsSeenInLogos/WM.png, /static/AsSeenInLogos/2x/WM.png 2x",
      title: "WM",
      href: "https://citywire.co.uk/wealth-manager/news/asset-manager-heavyweights-commit-1m-to-start-up-fund-platform/a1409038?ref=author/dcampbell",
    },
    {
      src: "/static/AsSeenInLogos/sifted.png",
      srcSet:
        "/static/AsSeenInLogos/sifted.png, /static/AsSeenInLogos/2x/sifted.png 2x",
      title: "Sifted",
      href: "https://sifted.eu/",
    },
    {
      src: "/static/AsSeenInLogos/FT-Advisor.png",
      srcSet:
        "/static/AsSeenInLogos/FT-Advisor.png, /static/AsSeenInLogos/2x/FT-Advisor.png 2x",
      title: "FT Adviser",
      href: "https://www.ftadviser.com/platforms/2022/03/11/tillit-ceo-on-filling-gap-between-advice-and-diy-platforms/",
    },
    {
      src: "/static/AsSeenInLogos/alt-fi.png",
      srcSet:
        "/static/AsSeenInLogos/alt-fi.png, /static/AsSeenInLogos/2x/alt-fi.png 2x",
      title: "AltFi",
      href: "https://www.altfi.com/article/8590_tillit-launches-diy-investment-platform-with-falling-fees-for-investors-with-a-long-term-view",
    },
    {
      src: "/static/AsSeenInLogos/Tech-Funding-News.png",
      srcSet:
        "/static/AsSeenInLogos/Tech-Funding-News.png, /static/AsSeenInLogos/2x/Tech-Funding-News.png 2x",
      title: "Tech Funding News",
      href: "https://techfundingnews.com/baillie-gifford-former-fund-manager-raises-3-6m-in-seed-funding-to-revolutionise-how-people-invest/?fbclid=IwAR3nEqog7fzrWHFVaCdpdGK49bdlEa5bUAx3tAHh5ahDYGdBzkRrt_jfVmc",
    },
    {
      src: "/static/AsSeenInLogos/Kepler.png",
      srcSet:
        "/static/AsSeenInLogos/Kepler.png, /static/AsSeenInLogos/2x/Kepler.png 2x",
      title: "Kepler",
      href: "https://www.trustintelligence.co.uk/investor/articles/opinion-investor-podcast-trust-issues-3-tillit-founder-and-ceo-felicia-hjertman-retail-oct-2022",
    },
    {
      src: "/static/AsSeenInLogos/Finimize.png",
      srcSet:
        "/static/AsSeenInLogos/Finimize.png, /static/AsSeenInLogos/2x/Finimize.png 2x",
      title: "Finimize",
      href: "https://www.youtube.com/watch?v=TqNZR9mjb0A",
    },
  ];

  return (
    <Container
      padding={isFullWidth ? Padding.vertical : Padding.both}
      {...props}
      fullWidth={isFullWidth}
    >
      <AsSeenInContainer>
        {slice.primary.content && (
          <ContentWrapper>
            <CustomRichText
              field={slice.primary.content.richText}
              context={context}
            />
          </ContentWrapper>
        )}
        <Swiper
          watchOverflow
          slidesPerView={"auto"}
          breakpoints={{
            200: { slidesPerView: "auto", spaceBetween: 46 },
            500: { slidesPerView: "auto", spaceBetween: 46 },
            768: { slidesPerView: "auto", spaceBetween: 46 },
            960: { slidesPerView: "auto", spaceBetween: 46 },
            1280: { slidesPerView: "auto", spaceBetween: 46 },
            1920: { slidesPerView: "auto", spaceBetween: 46 },
          }}
          loop={true}
          speed={3500}
          spaceBetween={46}
          height={60}
          autoplay={{
            delay: 0,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          keyboard={{
            enabled: true,
          }}
          modules={[Autoplay, Navigation, Keyboard]}
        >
          {logosArr.map((logo) => {
            return (
              <SwiperSlide key={logo.title}>
                <LogoLink href={logo.href} target="_blank">
                  <LogoImg src={logo.src} srcSet={logo.src} alt={logo.title} />
                </LogoLink>
              </SwiperSlide>
            );
          })}
        </Swiper>
        {hasFooterOptions &&
          slice.primary.cta_options.document.data.slices.length > 0 && (
            <SliceFooterActions
              slice={slice.primary.cta_options.document.data.slices[0]}
              align="center"
              context={context}
            />
          )}
        <RiskWarningAndDisclosures
          riskWarning={slice.primary.risk_warning}
          content={slice.primary.below_cta_content?.richText || null}
          context={context}
        />
      </AsSeenInContainer>
    </Container>
  );
};
export default FeaturedInLogos;

export const query = graphql`
  fragment FeaturedInLogosDefault on PrismicFeaturedInLogosDefault {
    id
    primary {
      full_width
      content {
        richText
      }
      cta_options {
        uid
        document {
          ...callToActions
        }
      }
      risk_warning
      below_cta_content {
        richText
      }
    }
    slice_type
    slice_label
    variation
  }
`;
